import { NextSeoProps } from "next-seo"
import { webUrl } from "./config/web"

export const fbAppId = "2292387061015118"

const defaultSeo: NextSeoProps = {
  defaultTitle: "Kroužky programování pro děti 2025 | 9-15 let",
  description: "Moderní kroužky programování pro děti od 9 do 15 let. Připravujeme mladé talenty na digitální budoucnost!",
  canonical: webUrl,
  openGraph: {
    type: "website",
    locale: "cs_CZ",
    siteName: "Vzdělání budoucnosti",
    url: webUrl,
    images: [
      {
        url: `${webUrl}/images/web_bg_2025_1920x1080.jpg`,
        width: 1920,
        height: 1080,
        alt: "Širokoúhlý Open Graph obrázek"
      },
      {
        url: `${webUrl}/images/web_bg_2025_1200x630.jpg`,
        width: 1200,
        height: 630,
        alt: "Optimalizovaný obrázek pro Facebook"
      }
    ]
  },
  facebook: {
    appId: fbAppId
  }
}

export default defaultSeo
